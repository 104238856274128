#chat-main .str-chat__channel-list {
  width: 30%;
}

#chat-main .str-chat__channel {
  width: 100%;
}

#chat-main .str-chat__thread {
  width: 45%;
}

#chat-main .str-chat__message-actions-box .str-chat__message-actions-list {
  min-width: 4rem;
}

#chat-main .emoji-mart {
  max-width: 338px;
}
