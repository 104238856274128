#chat-main .str-chat__loading-channels {
    width: 100%;
    height: 100%;
}

#chat-main .str-chat.str-chat-channel-list {
    display: flex;
    width: 100%;
    padding-top: 60px;
    justify-content: center;
    background-color: #fff;
    z-index: 1;
}

#chat-main .str-chat__channel-list-messenger__main {
    padding: 0;
    background-color: #fff;
}

#chat-main .str-chat.messaging {
    background-color: #fff;
}
